import React from 'react'

const Notification = () => {
  return (
    <div>
      <h1> Notification Comming soon</h1>
    </div>
  )
}

export default Notification
